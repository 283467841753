import '@firebase/functions'
import i18n from '../../i18n'
//
const getDefaultState = () => ({
    quoteSurveyQuestions: [
      {
        text: i18n.t('quotePage.areYouCandian'),
        type: 'radio',
        answer: null
      },
      {
        text: i18n.t('quotePage.last12Months'),
        type: 'radio',
        answer: null
      }
    ],
    quoteCustomerDob: null,
    quoteCustomerSex: null,
    quoteTerm: null,
    quoteCoverage: null
})
const state = getDefaultState()

const getters = {
  getQuoteSurveyQues: (state) => state.quoteSurveyQuestions,
  getQuoteCustomerDob: (state) => state.quoteCustomerDob,
  getQuoteCustomerSex: (state) => state.quoteCustomerSex,
  getQuoteTerm: (state) => state.quoteTerm,
  getQuoteCoverage: (state) => state.quoteCoverage,
}

const mutations = {
  setQuoteSurveyQues (state, value) {
    state.quoteSurveyQuestions = value
  },
  setQuoteCustomerDob: (state, value) => {
     state.quoteCustomerDob = value
  },
  setQuoteCustomerSex: (state, value) => {
     state.quoteCustomerSex = value
  },
  setQuoteTerm: (state, value) => {
    state.quoteTerm = value
  },
  setQuoteCoverage: (state, value) => {
    state.quoteCoverage = value
  }
}
export default {
  state,
  getters,
  mutations,
}
