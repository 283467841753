import { ValidationProvider, ValidationObserver, extend, localize, required } from 'vee-validate'

import * as rules from 'vee-validate/dist/rules'
import errorMessages from '@/consts/errorMessages'
import i18n from './i18n'
import moment from 'moment'

extend('requiredOption', {
  ...rules.required,
  message: (x, y) => i18n.t('forms.requiredOption')
})
extend('disposable', {
  ...rules.regex,
  validate: (value) => {
    const junkList = [
      '10minutemail.com',
      'guerrillamail.com',
      'sharklasers.com',
      'grr.la',
      'guerrillamail.biz',
      'guerrillamail.net',
      'guerrillamail.org',
      'mailinator.com',
      'temp-mail.org',
      'tempmail.com',
      'temp-mail.com',
      'throwawaymail.com',
      'fakemailgenerator.com',
      'maildrop.cc',
      'yopmail.com',
      'yopmail.fr',
      'yopmail.net',
      'e4ward.com',
      'trashmail.com',
      'trash-mail.com',
      'trashmail.me',
      'getnada.com',
      'emailondeck.com',
      'mailcatch.com',
      'anonymail.com',
      'inbox.lv',
      'mailnesia.com',
      'dispostable.com',
      'mintemail.com',
      'mohmal.com',
      'emailtemporal.org',
      'tempail.com',
      'tempmail2.com',
      'mytemp.email',
      'temporary-email.com',
      'sneakemail.com',
      'emailabox.pro'
    ]
    return !junkList.find(item => value.includes(item))
  },
  message: (x, y) => i18n.t('forms.validEmail')
})
extend('requiredWithZero', {
  ...rules.required,
  validate: (value) => {
    return value.length > 0
  },
  message: (x) => i18n.t('forms.required')

})
extend('passport', {
  validate (value) {
    const pattern = /^[A-Za-z]{2}\d{6}$/
    return pattern.test(value)
  },
  message: (x, y) => i18n.t('forms.passport')
})
extend('min_value', {
  validate (value, [min_value]) {
    return value < min_value
  },
  message: (x, y) => {
    return i18n.t('forms.min_value').replace('%%value%%', y._value_)
  }
})
extend('provincialhealthcard', {
  validate (value) {
    const combinedPattern = /^\d{9}$|^\d{10}[A-Za-z]{2}$|^[A-Za-z]{4}\d{8}$|^\d{12}$|^\d{10}$|^\d{8}$/
    return combinedPattern.test(value)
  },
  message: () => i18n.t('forms.healthCard')
})
extend('driverslicence', {
  validate (value) {
    const combinedPattern = /^[A-Za-z]{2}\d{4}$|^[A-Za-z]\d{5}$|^\d{6}$|^\d{5}-\d{3}$|^\d{7,8}$|^[A-Za-z]{7}\d{3}[A-Za-z0-9]{2}$|^[A-Za-z]\d{6}\d{3}$|^[A-Za-z]{2}\d{8}$|^\d{7}$|^\d{5,6}$|^[A-Za-z]{5}\d{2}\d{2}\d{2}\d{3}$|^[A-Za-z]\d{4}\d{4}\d{2}\d{2}\d{2}$|^\d{4}\d{2}\d{2}\d{2}\d{2}$|^\d{8}$|^\d{6}|^[a-zA-Z]\d{12}$/
    return combinedPattern.test(value)
  },
  message: () => i18n.t('forms.drivingLicence')
})
extend('number', {
  validate (value) {
    try {
      return /^\d+$/ig.test(value.replace(/[\$,]/ig, ''))
    } catch (e) {
      return /^\d+$/ig.test(value)
    }
  },
  message: () => i18n.t('forms.number')
})
extend('validi18nName', {
  validate (value, [, spaces = true]) {
    const trimmedValue = value.trim()
    if (spaces) {
      return /^[A-Za-zÀ-ÖØ-öø-ÿ\s.-]+(?:[ '-][A-Za-zÀ-ÖØ-öø-ÿ\s.-]+)*$/g.test(trimmedValue)
    } else {
      return /^[A-Za-zÀ-ÖØ-öø-ÿ.-]+$/g.test(trimmedValue)
    }
  },
  message: () => i18n.t('forms.name')
})
extend('range', {
  validate: (value, [min, max]) => {
    try {
      return +value.replace(/[$,]/ig, '') >= min && +value.replace(/[$,]/ig, '') <= max
    } catch (e) {
      return value >= min && value <= max
    }
  },
  message: (x, y) => {
    let min = y['0']
    let max = y['1']
    const isCurrency = y[2] ?? false
    let locale = 'en-US'
    if (i18n.locale === 'fr') {
      locale = 'fr-FR'
    }
    if (isCurrency) {
      min = formatCurrency(min, 'USD', locale).replace('$US', '$')
      max = formatCurrency(max, 'USD', locale).replace('$US', '$')
    }
    return i18n.t('forms.betweenRange').replace('%min%', min).replace('%max%', max)
  }
})
extend('creditCardNumber', {
  validate: (value) => {
    const regex = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/
    return regex.test(value.replaceAll(' ', ''))
  },
  message: () => i18n.t('forms.creditCard')
})
extend('creditCardExp', {
  validate: (value) => {
    const regex = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/
    return regex.test(value.replaceAll(' ', ''))
  },
  message: () => i18n.t('forms.creditCard')
})
extend('unitRange', {
  validate: (value, [min, max]) => {
    return value >= min && value <= max
  },
  message: (x, y) => {
    let min = y['0']
    let max = y['1']
    let unit = y['2']
    return i18n.t('forms.unitRange').replaceAll('%min%', min).replaceAll('%max%', max).replaceAll('%unit%', unit)
  }
})
extend('postalCode', {
  message: (x) => i18n.t('forms.regex'),
  validate: (postalCode) => {
    const ca = new RegExp(/([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i)

    return ca.test(postalCode.toString().replace(/\W+/g, ''))
  },
})
extend('caPhone', {
  message: (x) => i18n.t('forms.regex'),
  validate: (postalCode) => {
    const ca = new RegExp(/^\(?([2-9]\d{2})\)?[-. ]?([2-9]\d{2})[-. ]?(\d{4})$/i)

    return ca.test(postalCode.toString().replace(/\W+/g, ''))
  },
})
extend('maxRange', {
  validate: (value, [min, max]) => {
    return value.length >= min && value.length <= max
  },
  message: (x, y) => {
    let min = y['0']
    let max = y['1']
    const isCurrency = y[2] ?? false
    let locale = 'en-US'
    if (i18n.locale === 'fr') {
      locale = 'fr-FR'
    }
    if (isCurrency) {
      min = formatCurrency(min, 'USD', locale).replace('$US', '$')
      max = formatCurrency(max, 'USD', locale).replace('$US', '$')
    }
    return i18n.t('forms.betweenRange').replace('%min%', min).replace('%max%', max)
  }
})
extend('maxLength', {
  validate: (value, [max]) => {
    return value.length > max
  },
  message: (x, y) => {
    let max = y['0']
    return i18n.t('forms.maxLength').replace('%max%', max)
  }
})

extend('confirmed', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: (field, { target }) => {
    return i18n.t('forms.confirmedPassword', { target: target })
  }
})
extend('confirmed', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: (field, { target }) => {
    return i18n.t('forms.confirmedPassword', { target: target })
  }
})
extend('alcoholic', {
  params: ['target'],
  validate (value, { target }) {
    return target && value >= 1
  },
  message: (field, { target }) => {
    return i18n.t('forms.confirmedPassword', { target: target })
  }
})
extend('validateDate', {
  validate (value, { minDate, maxDate, fieldName }) {
    const getMinDate = moment().subtract(70, 'years').format('YYYY-MM-DD')
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/
    const formatMinDate = moment(minDate).subtract(1, 'year').format('MM/DD/YYYY')
    const formatMaxDate = moment(maxDate).format('MM/DD/YYYY')
    const currentDate = moment(value, 'MM/DD/YYYY')
    if (!currentDate.isValid()) {
      return i18n.t('errorMessages.invalidDate', { fieldName })
    }
    if (currentDate.isBefore(getMinDate)) {
      return i18n.t('errorMessages.dateBeforeMin', { fieldName, formatMinDate })
    }
    if (currentDate.isAfter(maxDate)) {
      return i18n.t('errorMessages.dateAfterMax', { fieldName, formatMaxDate })
    }
    return true
  },
  params: ['minDate', 'maxDate', 'fieldName'],
  message: '{_field_} must be a valid date within the specified range'
})
// Loop over all rules
for (let rule in rules) {
  extend(rule, rules[rule])
}
export const formatCurrency = (value, currency, locale = 'en-US', isMin) => {
   if (typeof value !== 'string') {
    value = String(value).replace(/[^0-9.-]+/g, '')
  }
  const numericValue = value.replace(/[\$,]/ig, '')
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: isMin ? 0 : 2,
    maximumFractionDigits: isMin ? 0 : 2
  }).format(numericValue)?.replaceAll('US', '')
}
// Setup localization
localize(errorMessages)
