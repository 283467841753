import firebase from '@firebase/app'
import '@firebase/functions'
import Vue from 'vue'
import ChargeOverJS from '../../plugins/chargeover'
import store from '../store'

const getDefaultState = () => ({
  CATermPremium: null,
  CATermPolicyFee: null,
  CATermDecision: null,
  CATermLifeTerm: null,
  CATermLifeCoverage: null,
  CATermPolicyNumber: null,
  CATermBeneficiaries: null,
  CATermCCValid: null,
  CATermCCError: null,
  CATermACHValid: null,
  CATermACHError: null,
  CATermInsuranceNeed: null,
  CATermLifestyleQuestions: null,
  CATermMedicalQuestions: null,
  CATermPersonalQuestions: null,
  CATermAcknowledgements: null,
  CATermTemplateId: null,
  CATermPolicies: null,
  CATermCustomerId: null,
  CATermCustomerSubscriptionId: null,
  CATermPaymentId: null,
  CATermPaymentSuccessful: null,
  CATermPaymentMethodId: null,
  applicationID: null,
  application: {
    applicationStep: 1,
    user: {
      firstName: null,
      lastName: null,
      middleInitial: null,
      suffix: null,
      address: {
        addr1: null,
        city: null,
        province: null,
        postalCode: null,
      },
      phoneNumber: null,
      emailAddress: null,
      password: null,
      policyLanguage: null,
      centimeters: null,
      inches: null,
      feet: null,
      kilograms: null,
      pounds: null,
      dob: null,
      sex: null,
      coverage: null,
      term: 10,
      smoker: null,
    },
    surveyQuestions: {}
  },
})

const state = getDefaultState()

const getters = {
  getProperty: (state) => (property) => state[property],
  getCATermPolicies: (state) => state.CATermPolicies,
  getCATermCustomerId: (state) => state.CATermCustomerId,
  getCATermCustomerSubscriptionId: (state) => state.CATermCustomerSubscriptionId,
  getCATermPaymentMethodId: (state) => state.CATermPaymentId,
  getCATermPaymentSuccessful: (state) => state.CATermPaymentSuccessful,
  getCaTermApplication: (state) => state.application,
  getCaTermApplicationID: (state) => state.applicationID
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },
  setStateProperty: (state, { property, value }) => {
    if (property in state) {
      state[property] = value
    }
  },
  updateApplication: (state, options) => {
    state.application = { ...state.application, ...options }
  },
  setCATermPaymentSuccessful: (state, value) => {
    state.CATermPaymentSuccessful = value
  }
}

const actions = {
  async callFirebaseFunction ({ commit }, { functionName, payload, onSuccess }) {
    commit('setLoading', true)
    const locale = Vue.prototype.$Storage.getCookie('locale') || 'en'
    try {
     const functions = firebase.functions()
     // functions.useEmulator('127.0.0.1', 5001)
      const { data } = await firebase.functions().httpsCallable(functionName)({ ...payload, locale })
      const { response } = data

      if (!response || response.haserror) {
        commit('setError', response.message || 'Unable to process request')
      }

      if (onSuccess) {
        onSuccess(commit, response)
      }
    } catch (error) {
      commit('setError', error.message || 'Unable to process request')
    } finally {
      commit('setLoading', false)
    }
  },
  async callPaymentFunction ({ commit }, { functionName, payload, onSuccess }) {
    commit('setLoading', true)

    try {
      const { data } = await firebase.functions().httpsCallable(functionName)(payload)
      const { response } = data

      if (!response || response.haserror) {
        commit('setError', 'Unable to process request. Please check your payment information and try again.')
      }

      if (onSuccess) {
        onSuccess(commit, response)
      }
    } catch (error) {
      commit('setError', 'Unable to process request. Please check your payment information and try again.')
    } finally {
      commit('setLoading', false)
    }
  },

  setCATermLifestyleQuestions ({ commit }, payload) {
    commit('setStateProperty', { property: 'CATermLifestyleQuestions', value: payload })
  },

  setCATermPersonalQuestions ({ commit }, payload) {
    commit('setStateProperty', { property: 'CATermPersonalQuestions', value: payload })
  },

  setCATermMedicalQuestions ({ commit }, payload) {
    commit('setStateProperty', { property: 'CATermMedicalQuestions', value: payload })
  },

  setCATermAcknowledgements ({ commit }, payload) {
    commit('setStateProperty', { property: 'CATermAcknowledgements', value: payload })
  },

  setCATermInsuranceNeed ({ commit }, payload) {
    commit('setStateProperty', { property: 'CATermInsuranceNeed', value: payload })
  },

  resetCATermPurchaseErrors ({ commit }) {
    commit('setStateProperty', { property: 'CATermCCError', value: null })
    commit('setStateProperty', { property: 'CATermACHError', value: null })
  },

  setCATermCCValid ({ commit }, payload) {
    commit('setStateProperty', { property: 'CATermCCValid', value: payload })
  },

  setCATermBeneficiaries ({ commit }, payload) {
    commit('setStateProperty', { property: 'CATermBeneficiaries', value: payload })
  },

  setCATermDecision ({ commit }, payload) {
    commit('setStateProperty', { property: 'CATermDecision', value: payload })
  },
  setCATermPremium ({ commit }, payload) {
    commit('setStateProperty', { property: 'CATermPremium', value: payload })
  },
  async getCATermPremium ({ dispatch }, payload) {
    return dispatch('callFirebaseFunction', {
      functionName: 'getCATermPremium',
      payload,
      onSuccess: (commit, response) => {
        commit('setStateProperty', { property: 'CATermPremium', value: response.policyPremium })
        commit('setStateProperty', { property: 'CATermLifeTerm', value: payload.product })
        commit('setStateProperty', { property: 'CATermLifeCoverage', value: payload.amount })
        commit('setStateProperty', { property: 'CATermPolicyFee', value: response.policyfee })
      }
    })
  },

  async getCATermDecision ({ dispatch }, payload) {
    return dispatch('callFirebaseFunction', {
      functionName: 'getCATermDecision',
      payload,
      onSuccess: (commit, response) => {
        const decision = response.decline ? 'Declined' : 'Approved'
        commit('setStateProperty', { property: 'CATermDecision', value: decision })
      }
    })
  },

  async CATermBindPolicy ({ dispatch }, payload) {
    return dispatch('callFirebaseFunction', {
      functionName: 'CATermBindPolicy',
      payload: { ...payload, subscriptionId: state.CATermCustomerSubscriptionId },
      onSuccess: (commit, response) => {
        commit('setStateProperty', { property: 'CATermPolicyNumber', value: response.policyNum })
        commit('setStateProperty', { property: 'CATermTemplateId', value: response.templateId })
      }
    })
  },

  async CATermNeedsAssessment ({ dispatch }, payload) {
    return dispatch('callFirebaseFunction', {
      functionName: 'CATermNeedsAssessment',
      payload,
      onSuccess: (commit, response) => {
        commit('setStateProperty', { property: 'CATermInsuranceNeed', value: response.totalInsuranceNeeded })
      }
    })
  },

  async getCATermPolicy ({ dispatch }, payload) {
    return dispatch('callFirebaseFunction', {
      functionName: 'getCATermPolicyPdf',
      payload
    })
  },

  async sendCATermPolicyPdfWithTemplateId ({ dispatch, getters }, postData) {
    const payload = {
      userkey: getters.activeUser?.key ?? getters.getuser.id,
      policyNum: getters.getProperty('CATermPolicyNumber'),
      templateId: getters.getProperty('CATermTemplateId'),
      firstname: postData?.firstname || '',
      partnerId: postData?.partnerId
    }

    return dispatch('callFirebaseFunction', {
      functionName: 'sendCATermPolicyPdf',
      payload
    })
  },

  async sendCATermPolicyPdfWithoutTemplateId ({ dispatch, getters }, payload) {
    return dispatch('callFirebaseFunction', {
      functionName: 'sendCATermPolicyPdf',
      payload: {
        userkey: getters.activeUser?.key ?? getters.getuser.id,
        policyNum: payload.policyNum
        // templateId is not included
      }
    })
  },
  // async makeChargeoverCCPayment ({ dispatch }, payload) {
  //   return dispatch('processPayment', {
  //     paymentType: 'CC',
  //     paymentData: payload.creditCardData,
  //     signupPayload: payload.signupPayload
  //   })
  // },

  // async makeChargeoverACHPayment ({ dispatch }, payload) {
  //   return dispatch('processPayment', {
  //     paymentType: 'ACH',
  //     paymentData: payload.achData,
  //     signupPayload: payload.signupPayload
  //   })
  // },

  // async processPayment ({ commit, dispatch }, { paymentType, paymentData, signupPayload }) {
  //   commit('setLoading', true)
  //   try {
  //     const validateFunction = `validate${paymentType}`
  //     const response = await ChargeOverJS[validateFunction](paymentData)

  //     if (response.isValid) {
  //       commit('setStateProperty', { property: `CATerm${paymentType}Valid`, value: true })
  //       await ChargeOverJS[`signupCustomer${paymentType}`](signupPayload)

  //       await dispatch('sendCATermPolicyPdfWithTemplateId')
  //     } else {
  //       commit('setStateProperty', { property: `CATerm${paymentType}Error`, value: response.message })
  //       // Handle validation error here
  //     }
  //   } catch (error) {
  //     commit('setError', error.message || 'Unable to process payment')
  //     // Handle other errors here
  //   } finally {
  //     commit('setLoading', false)
  //   }
  // },

  async createCATermCustomer ({ dispatch, getters }, createCATermCustomerPayload) {
    if (getters.getCATermCustomerId) { return }
    if (getters.error) {
      return
    }
    await dispatch('callPaymentFunction', {
      functionName: 'createCATermCustomer',
      payload: createCATermCustomerPayload,
      onSuccess: (commit, response) => {
        commit('setStateProperty', { property: 'CATermCustomerId', value: response.customerId })
      }
    })
  },

  async storeCATermCreditCard ({ dispatch, getters }, storeCATermCreditCardPayload) {
    if (getters.getCATermPaymentMethodId) { return }
    if (getters.error) {
      return
    }
    await dispatch('callPaymentFunction', {
      functionName: 'storeCATermCreditCard',
      payload: {
        customer_id: getters.getCATermCustomerId,
        number: storeCATermCreditCardPayload.number,
        expdate_month: storeCATermCreditCardPayload.expdate_month,
        expdate_year: storeCATermCreditCardPayload.expdate_year,
        cvv: storeCATermCreditCardPayload.cvv,
        name: storeCATermCreditCardPayload.name,
        address: storeCATermCreditCardPayload.address,
        city: storeCATermCreditCardPayload.city,
        state: storeCATermCreditCardPayload.state,
        postcode: storeCATermCreditCardPayload.postcode,
        country: 'Canada'
      },
      onSuccess: (commit, response) => {
        commit('setStateProperty', { property: 'CATermPaymentId', value: response.paymentId })
      }
    })
  },

  async storeCATermACH ({ dispatch, getters }, storeCATermACHPayload) {
   if (getters.getCATermPaymentMethodId) return
    if (getters.error) {
      return
    }
    return dispatch('callPaymentFunction', {
      functionName: 'storeCATermACH',
      payload: {
        customer_id: getters.getCATermCustomerId,
        type: storeCATermACHPayload.type,
        routing: storeCATermACHPayload.routing,
        number: storeCATermACHPayload.number,
        name: storeCATermACHPayload.name,
        bank: storeCATermACHPayload.bank,
      },
      onSuccess: (commit, response) => {
        commit('setStateProperty', { property: 'CATermPaymentId', value: response.paymentId })
      }
    })
  },

  async subscribeCATerm ({ dispatch, getters }, subscribeCATermPayload) {
   if (getters.getCATermCustomerSubscriptionId) { return }
    if (getters.error) {
      return
    }
    await dispatch('callPaymentFunction', {
      functionName: 'subscribeCATerm',
      payload: {
        payment: {
          customerId: getters.getCATermCustomerId,
          cycle: subscribeCATermPayload.payment.cycle,
          paymethod: subscribeCATermPayload.payment.paymethod, // ach|creditcard
          paymethodId: getters.getCATermPaymentMethodId,
        },
        premium: subscribeCATermPayload.premium
      },
      onSuccess: (commit, response) => {
        commit('setCATermPaymentSuccessful', true)
        commit('setStateProperty', { property: 'CATermCustomerSubscriptionId', value: response.id })
      }
    })
  },

  async makeCATermCreditCardPayment ({ commit, dispatch }, payload) {
    try {
      commit('setLoading', true)
      commit('clearError')
      // Create customer and wait for completion
      await dispatch('createCATermCustomer', payload.createCustomerPayload)

      // Store credit card information and wait for completion
      await dispatch('storeCATermCreditCard', payload.storeCATermCreditCardPayload)

      // Subscribe to CATerm and wait for completion
      await dispatch('subscribeCATerm', payload.subscribeCATermPayload)
    } catch (error) {
      console.error('Error making credit card payment. Please check payment details and try again.', error)
      commit('setError', 'Error making credit card payment. Please check payment details and try again.')
      commit('setCATermPaymentSuccessful', false)
    } finally {
      commit('setLoading', false)
    }
  },

  async makeCATermACHPayment ({ dispatch, commit }, payload) {
    try {
      commit('setLoading', true, { state })
      commit('clearError')
      await dispatch('createCATermCustomer', payload.createCustomerPayload)
      await dispatch('storeCATermACH', payload.storeCATermACHPayload)
      await dispatch('subscribeCATerm', payload.subscribeCATermPayload)
    } catch (error) {
      console.error('Error making ACH payment. Please check payment details and try again.', error)
      commit('setError', 'Error making ACH payment. Please check payment details and try again.')
      commit('setCATermPaymentSuccessful', false)
    } finally {
      commit('setLoading', false)
    }
  },

  async getCATermLifestyleQuestions ({ dispatch }) {
    return dispatch('callFirebaseFunction', {
      functionName: 'getCATermLifestyleQuestions',
      payload: {},
      onSuccess: (commit, response) => {
        commit('setStateProperty', { property: 'CATermLifestyleQuestions', value: response.questions })
      }
    })
  },

  async getCATermMedicalQuestions ({ dispatch }) {
    return dispatch('callFirebaseFunction', {
      functionName: 'getCATermMedicalQuestions',
      payload: {},
      onSuccess: (commit, response) => {
        commit('setStateProperty', { property: 'CATermMedicalQuestions', value: response.questions })
      }
    })
  },

  async getCATermPersonalQuestions ({ dispatch }) {
    return dispatch('callFirebaseFunction', {
      functionName: 'getCATermPersonalQuestions',
      payload: {},
      onSuccess: (commit, response) => {
        commit('setStateProperty', { property: 'CATermPersonalQuestions', value: response.questions })
      }
    })
  },

  async loaduserpoliciesCATerm ({ commit, state }, payload) {
    try {
      const { userkey } = payload
      const snapshot = await firebase
        .database()
        .ref(`users/${userkey}/policies/parachute`)
        .once('value')

      const policiesObj = snapshot.val()

      if (!policiesObj) {
        throw new Error('No policy data found')
      }

      // Assuming each property in policiesObj is a policy
      const policies = Object.values(policiesObj).map(policy => {
        // Extracting premium and policy fee, and ensuring they are numbers
        const premium = policy.coverage?.paymentFrequency === 'monthly' ? parseFloat(policy.coverage?.monthlyPrem) : parseFloat(policy.coverage?.annualPrem)
        const policyFee = parseFloat(policy.coverage?.policyFee)

        // Calculating the price by summing premium and policy fee
        const price = (isNaN(premium) ? 0 : premium) + (isNaN(policyFee) ? 0 : policyFee)

        return {
          PolicyNumber: policy.policyNum,
          Coverage: policy.coverage?.coverageAmount,
          Term: policy.coverage?.product,
          Premium: policy.coverage?.paymentFrequency === 'monthly' ? policy.coverage?.monthlyPrem : policy.coverage?.annualPrem,
          PaymentFrequency: policy.coverage?.paymentFrequency,
          PolicyFee: policy.coverage?.paymentFrequency === 'monthly' ? '2.70' : '30.00',
          Price: price.toFixed(2), // formatted price
          EffectiveDate: policy.effectiveDate,
          Created: policy.createdDate // Assuming createdDate is the correct field
        }
      })
      commit('setStateProperty', { property: 'CATermPolicies', value: policies })
    } catch (error) {
      console.error('Error loading user policies: ', error)
      // Handle the error appropriately
    }
  },
 async getSettings () {
  const collectionRef = firebase.database().ref('settings')
  try {
    const snapshot = await collectionRef.once('value')
    if (snapshot.exists()) {
      const data = snapshot.val()
      return data
    } else {
      return null
    }
  } catch (error) {
    console.error('Error accessing database:', error)
    throw error
  }
}

}

export default {
  state,
  getters,
  actions,
  mutations,
}
