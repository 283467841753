import firebase from '@firebase/app'
import '@firebase/functions'
import i18n from '../../i18n'
//
const getDefaultState = () => ({
    VerifyEmail: '',
    IsEmailVerified: false,
    isEmailExist: false,
    validationOtpError: '',
    validationSuccess: '',
    showLoader: false,
    apiError: false,
  showApplicationNotification: true
})
const state = getDefaultState()

const getters = {
  getVerifyEmail: (state) => state.VerifyEmail,
  getIsEmailVerified: (state) => state.IsEmailVerified,
  getIsEmailExist: (state) => state.isEmailExist,
  getValidationOtpError: (state) => state.validationOtpError,
  getValidationSuccess: (state) => state.validationSuccess,
  getShowLoader: (state) => state.showLoader,
  getApiError: (state) => state.apiError
}

const mutations = {
  setVerifyEmail (state, value) {
    state.VerifyEmail = value
  },
  resetVerifyEmail: (state, value) => {
     state.VerifyEmail = ''
  },
  setIsEmailVerified: (state, value) => {
     state.IsEmailVerified = value
  },
  setIsEmailExist: (state, value) => {
     state.isEmailExist = value
  },
  setValidationOtpError: (state, value) => {
     state.validationOtpError = value
  },
  setValidationSuccess: (state, value) => {
     state.validationSuccess = value
  },
  setShowLoader: (state, value) => {
     state.showLoader = value
  },
  setApiError: (state, value) => {
     state.apiError = value
  },
}
const handleError = (commit, loaderKeys) => {
  commit('setApiError', true)
  commit('setValidationOtpError', i18n.t('internalServerError'))
  loaderKeys.forEach((loaderKey) => commit(loaderKey, false))
}
const actions = {
    verifyEmail ({ commit, getters, dispatch }, payload) {
    commit('setShowLoader', true)
    let email = payload
    const functions = firebase.functions()
    // Create a reference to the callable function
    const emailVerify = functions.httpsCallable('emailVerify')
      return emailVerify(payload)
        .then(async (data) => {
          const code = data?.data?.response?.messageCode
          if (!data?.data?.response?.success) {
            commit('setValidationOtpError', i18n.t(`${code}`))
            commit('setShowLoader', false)
          } else {
            commit('setValidationOtpError', '')
            commit('setIsEmailVerified', true)
            commit('setValidationSuccess', i18n.t('emailSuccess'))
            dispatch('verifySignInEmail', {
              email: payload?.email,
              emailVerified: true
            })
            commit('setShowLoader', false)
          }
          //  commit('setVerifyEmail', 'test@gmail.com')
        })
        .catch((e) => {
          console.log({ e })
          handleError(commit, ['setShowLoader', 'setIsEmailExist'])
        })
    },
    sendOtp ({ commit }, payload) {
      commit('setVerifyEmail', payload?.email)
      commit('setShowLoader', true)
      const functions = firebase.functions()
       // Create a reference to the callable function
      const sendOtp = functions.httpsCallable('sendOtp')
      return sendOtp(payload)
        .then((res) => {
          commit('setShowLoader', false)
        })
        .catch(() => {
          handleError(commit, ['setShowLoader', 'setIsEmailExist'])
        })
    },
    checkEmailExist ({ commit }, payload) {
    commit('setShowLoader', true)
    const checkAccountKey = firebase.functions().httpsCallable('getAccountKeybyEmail')
     return checkAccountKey(payload)
      .then((snapshot) => {
          if (snapshot?.data?.response?.code === 404) {
              commit('setIsEmailExist', false)
              commit('setShowLoader', false)
          } else {
            commit('setValidationOtpError', i18n.t('emailExist'))
            commit('setIsEmailExist', true)
            commit('setShowLoader', false)
          }
        })
        .catch(() => {
          handleError(commit, ['setShowLoader', 'setIsEmailExist'])
          commit('setError', i18n.t('globals.errors.generic'))
          commit('setValidationOtpError', '')
        })
    },
    verifySignInEmail ({ commit }, payload) {
    const verifySignInEmail = firebase.functions().httpsCallable('verifySignInEmail')
     return verifySignInEmail(payload)
      .then((snapshot) => {
          if (snapshot?.data?.response?.success) {
             commit('setLoading', false)
          }
        })
        .catch(() => {
          handleError(commit, ['setShowLoader', 'setIsEmailExist'])
        })
    },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
