// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import firebase from 'firebase/app'
import '@firebase/database'
import '@firebase/analytics'
import VueGtag from 'vue-gtag'
import SequentialEntrance from 'vue-sequential-entrance'
import 'vue-sequential-entrance/vue-sequential-entrance.css'
import Hotjar from 'vue-hotjar'
import VueMask from 'v-mask'
import pdf from 'vue-pdf'
import VueEllipseProgress from 'vue-ellipse-progress'
import ErrorDialog from './views/dashboard/components/core/ErrorDialog'
import EllipseProgress from './views/dashboard/components/EllipseProgress'
import VueApexCharts from 'vue-apexcharts'
import ReveChat from './plugins/revechat'
import Storage from './plugins/storage'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import './i18nValidate'
import OtpVerfication from './components/base/OtpVerification/index'
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import IdleDetection from './plugins/idleDetection'
import GoogleTagManager from './plugins/google-tag-manager'
import './registerServiceWorker'

Vue.component('otp-verification', OtpVerfication)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.use(Storage)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('error-dialog', ErrorDialog)
Vue.component('ellipse-progress', EllipseProgress)
Vue.use(VueEllipseProgress)
Vue.use(VueMask)
Vue.use(SequentialEntrance)
Vue.use(pdf)
Vue.use(Hotjar, {
  id: '2579598' // Hotjar Site ID
})
Vue.use(ReveChat, {
  accountId: Vue.prototype.$Storage.getCookie('locale') === 'fr' ? process.env.VUE_APP_REVE_CHAT_FR : process.env.VUE_APP_REVE_CHAT_EN
})
Vue.use(IdleDetection, { idleTime: 15 * 60 * 1000 })
Vue.use(GoogleTagManager, { id: 'AW-16640072208' })

// eslint-disable-next-line handle-callback-err
Vue.config.errorHandler = function (err, vm, info) {
//
  // Here, you could send the error information to an external logging service
}

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.store = store
Vue.config.productionTip = true

if (process.env.VUE_ENVIRONMENT === 'production') { Vue.config.productionTip = false }

const analytics = firebase.analytics()

analytics.logEvent('Main Page load')
firebase.analytics().setUserProperties({ favorite_food: 'apples' })

const userPreference = localStorage.getItem('cookie_preference')
const isAnalyticsEnabled = userPreference === 'all'

Sentry.init({
  dsn: 'https://e43ab0fd2959fa472608174074f9bdea@o173497.ingest.sentry.io/4506755622699008',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^http:\/\/(\d{1,3}\.){3}\d{1,3}(:\d+)?/, /^https:\/\/(\d{1,3}\.){3}\d{1,3}(:\d+)?/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

Vue.use(VueGtag, {
  config: { id: 'G-CP9G0TRDZD' },
  appName: 'Blanket.com',
  pageTrackerScreenviewEnabled: true,
  enabled: isAnalyticsEnabled,
}, router)
axios.interceptors.response.use(response => {
  // store.commit('setLoading', false)
  return response
}, error => {
  store.commit('setLoading', false)
  return Promise.reject(error)
})
Vue.mixin({
  methods: {
    clickedField (value, dataPoint) {
      let eventName = 'Undefined Data Value'
      this.$gtag.event(eventName, { userId: this.getUserId(), dataPoint, action: 'Clicked field', data: `${value}` })
    },
    clickedFieldGlobal (value, dataPoint, eventName) {
      if (!eventName) {
        eventName = 'Undefined Event'
      }
      if (!dataPoint) {
        eventName = 'Undefined Data Point'
      }
      if (!value) {
        eventName = 'Undefined Data Value'
      }
      this.$gtag.event(eventName, { userId: this.getUserId(), dataPoint, action: 'Clicked field', data: `${value}` })
    },
    getUserId () {
      let userId = 'Visitor'
      if (this.$store.getters.getuser && this.$store.getters.getuser.id) {
        userId = this.$store.getters.getuser.id
      }
      return userId
    },
  },
})

const vm = new Vue({
  router,
  store,
  vuetify,
  i18n,
  data: {
    deferredPrompt: null
  },
  created () {
    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt)
  },
  beforeDestroy () {
    // Clean up the event listener when the root instance is destroyed
    window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt)
  },
  beforeCreate () {
    this.$i18n.locale = Vue.prototype.$Storage.getCookie('locale') || 'en'
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch('autoSignIn', user)
          .then(() => {
            // Actions to perform after sign-in
          })
          .catch(() => {
            // console.log(err)
          })
      }
    })
  },
  methods: {
    handleBeforeInstallPrompt (e) {
      e.preventDefault()
      console.log('beforeinstallprompt event fired')
      // Store the event so it can be triggered later.
      this.deferredPrompt = e
    },
    showInstallPrompt () {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt()
        this.deferredPrompt = null // Reset after showing
      }
    }
  },
  render: h => h(App),
}).$mount('#app')

export { vm }
