<template>
  <v-btn
    :class="buttonClasses"
    :disabled="isFormInvalid"
    :text="text"
    :block="block"
    :large="large"
    type="submit"
    @click="Signup"
    @keyup.enter="Signup"
  >
    <div v-if="buttonText2">
      <div v-if="!isMobile && !isTablet">
        {{ $t('profile.createOne') }}
      </div>
      <div v-else>
        {{ $t('profile.createOne') }}
      </div>
    </div>
    <div v-else>
      {{ $t('profile.createOne') }}
    </div>
  </v-btn>
</template>
<script>
  import { GT_EVENT, gtag_report_conversion } from '../../utils/common'

  export default {
    props: {
      buttonText: {
        type: String,
        required: true
      },
      buttonText2: {
        type: String,
        required: false
      },
      buttonClasses: {
        type: [String, Array],
        default: ''
      },
      email: {
        type: String,
        required: true
      },
      password: {
        type: String,
        required: false
      },
      preSignupCallback: {
        type: Function
      },
      postSignupCallback: {
        type: Function
      },
      isFormInvalid: {
        type: Boolean
      },
      block: {
        type: Boolean,
        default: false
      },
      large: {
        type: Boolean,
        default: false
      },
      text: {
        type: Boolean,
        required: false
      }
    },
    computed: {
      isEmailExist () {
        return this.$store.getters.getIsEmailExist
      },
      IsEmailVerified () {
        return this.$store.getters.getIsEmailVerified
      },
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      },
      isTablet () {
        return this.$vuetify.breakpoint.smOnly
      },
      lang () {
        return this.$store.getters.getLanguage
      },
      error () {
        return this.$store.getters.getErrors
      }
    },
    watch: {
      async IsEmailVerified (value) {
        if (value) {
          await this.$store.dispatch('createUserAndProfileForUser', {
            email: this.email,
            password: this?.password,
            preferredLang: this.lang
          })
          await this.$store.dispatch('verifySignInEmail', {
            email: this.email,
            emailVerified: true
          })
          if (this.postSignupCallback) {
            this.postSignupCallback()
          }
          this.$closeOtpModal()
        }
      }
    },
    mounted () {
      this.$store.commit('setLanguage', this.$i18n.locale)
    },
    methods: {
      async Signup (event) {
        event.preventDefault()
        // Signup logic here...
        let result
        if (this.preSignupCallback && typeof this.preSignupCallback === 'function') {
          result = await Promise.resolve(this.preSignupCallback())
        }
        const send_to = process.env.VUE_APP_REGISTER
        gtag_report_conversion(this.$gtag, send_to)
        if (!this.preSignupCallback || result) {
          const result2 = await this.$store.dispatch('checkEmailExist', this.email, { i18n: this.$i18n })
          if (!this.isEmailExist && !this.error) {
            this.$openOtpModal(this.email)
          }
        }
      }
    }
  }
</script>
