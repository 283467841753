import { firebase } from '@firebase/app'
import '@firebase/database'
import '@firebase/storage'
import 'firebase/functions'
import 'firebase/auth'
import moment from 'moment'

const state = {
  rentalDiscounts: [],
  rentalDiscountQuestions: [],
  revisedRentalQuote: null,
  customRentalQuote: null,
  rentalSurveyQuestions: [],
  renter: {},
  rentalQuote: {},
  propertyData: {},
  rentalQuotes: [],
  smallRentalQuote: {},
  mediumRentalQuote: {},
  largeRentalQuote: {},
  smallRentalQuoteNumber: '',
  mediumRentalQuoteNumber: '',
  largeRentalQuoteNumber: '',
  customRentalQuoteNumber: '',
  rentalQuoteNumber: '',
  propertyTypes: [],
  rentalAnswers: {},
  rentalLosses: [],
  rentalBldgProtection: {},
  rentalTransactionDetails: {},
  rentalPolicy: {},
  rentalPolicies: [],
  dwellUseTypes: [],
  rentersPdfs: [],
  stillwaterInterestTypes: [],
  stillwaterLOB: [],
  stillwaterPolicyCd: [],
  rentersQuoteWarning: '',
  rentalConstruction: '',
  renterMaritalStatus: null,
}

const getters = {
  getPropertyTypes (state) {
    return state.propertyTypes
  },
  getPropertyData (state) {
    return state.propertyData
  },
  getRentalQuotes (state) {
    return state.rentalQuotes
  },
  getSmallRentalQuote (state) {
    return state.smallRentalQuote
  },
  getMediumRentalQuote (state) {
    return state.mediumRentalQuote
  },
  getLargeRentalQuote (state) {
    return state.largeRentalQuote
  },
  getSmallRentalQuoteNumber (state) {
    return state.smallRentalQuoteNumber
  },
  getMediumRentalQuoteNumber (state) {
    return state.mediumRentalQuoteNumber
  },
  getLargeRentalQuoteNumber (state) {
    return state.largeRentalQuoteNumber
  },
  getCustomRentalQuoteNumber (state) {
    return state.customRentalQuoteNumber
  },
  getRentalQuoteNumber (state) {
    return state.rentalQuoteNumber
  },
  getRentalQuote (state) {
    return state.rentalQuote
  },
  getRenter (state) {
    return state.renter
  },
  getRentalSurveyQuestions (state) {
    return state.rentalSurveyQuestions
  },
  getCustomRentalQuote (state) {
    return state.customRentalQuote
  },
  getRevisedRentalQuote (state) {
    return state.revisedRentalQuote
  },
  getRentalDiscountQuestions (state) {
    return state.rentalDiscountQuestions
  },
  getRentalDiscounts (state) {
    return state.rentalDiscounts
  },
  getRentalAnswers (state) {
    return state.rentalAnswers
  },
  getRentalBldgProtection (state) {
    return state.rentalBldgProtection
  },
  getRentalAddress (state) {
    return state.rentalAddress
  },
  getRentalTransactionDetails (state) {
    return state.rentalTransactionDetails
  },
  getRentalPolicy (state) {
    return state.rentalPolicy
  },
  getRentalPolicies (state) {
    return state.rentalPolicies
  },
  getDwellUseTypes (state) {
    return state.dwellUseTypes
  },
  getRentersPdfs (state) {
    return state.rentersPdfs
  },
  getStillwaterInterestTypes (state) {
    return state.stillwaterInterestTypes
  },
  getStillwaterLOB (state) {
    return state.stillwaterLOB
  },
  getStillwaterPolicyCd (state) {
    return state.stillwaterPolicyCd
  },
  getRentalLosses (state) {
    return state.rentalLosses
  },
  getRentersQuoteWarning (state) {
    return state.rentersQuoteWarning
  },
  getRentalConstruction (state) {
    return state.rentalConstruction
  },
}
const mutations = {
  setPropertyTypes (state, payload) {
    state.propertyTypes = payload
  },
  setPropertyData (state, payload) {
    state.propertyData = payload
  },
  setRentalQuotes (state, payload) {
    state.rentalQuotes.push(payload)
  },
  setSmallRentalQuote (state, payload) {
    state.smallRentalQuote = payload
  },
  setMediumRentalQuote (state, payload) {
    state.mediumRentalQuote = payload
  },
  setLargeRentalQuote (state, payload) {
    state.largeRentalQuote = payload
  },
  setSmallRentalQuoteNumber (state, payload) {
    state.smallRentalQuoteNumber = payload
  },
  setMediumRentalQuoteNumber (state, payload) {
    state.mediumRentalQuoteNumber = payload
  },
  setLargeRentalQuoteNumber (state, payload) {
    state.largeRentalQuoteNumber = payload
  },
  setCustomRentalQuoteNumber (state, payload) {
    state.customRentalQuoteNumber = payload
  },
  setRentalQuoteNumber (state, payload) {
    state.rentalQuoteNumber = payload
  },
  setRentalQuote (state, payload) {
    state.rentalQuote = payload
  },
  setRenter (state, payload) {
    state.renter = payload
  },
  setRevisedRentalQuote (state, payload) {
    state.revisedRentalQuote = payload
  },
  setRentalSurveyQuestions (state, payload) {
    state.rentalSurveyQuestions = payload
  },
  setCustomRentalQuote (state, payload) {
    state.customRentalQuote = payload
  },
  setRentalDiscountQuestions (state, payload) {
    state.rentalDiscountQuestions = payload
  },
  clearCustomRentalQuote (state) {
    state.customRentalQuote = null
  },
  setRentalDiscounts (state, payload) {
    state.rentalDiscounts = payload
  },
  setRentalAnswers (state, payload) {
    state.rentalAnswers = payload
  },
  setRentalBldgProtection (state, payload) {
    state.rentalBldgProtection = payload
  },
  setRentalAddress (state, payload) {
    state.rentalAddress = payload
  },
  setRentalTransactionDetails (state, payload) {
    state.rentalTransactionDetails = payload
  },
  setRentalPolicy (state, payload) {
    state.rentalPolicy = payload
  },
  setRentalPolicies (state, payload) {
    state.rentalPolicies = payload
  },
  setDwellUseTypes (state, payload) {
    state.dwellUseTypes = payload
  },
  setRentersPdfs (state, payload) {
    state.rentersPdfs = payload
  },
  setStillwaterInterestTypes (state, payload) {
    state.stillwaterInterestTypes = payload
  },
  setStillwaterLOB (state, payload) {
    state.stillwaterLOB = payload
  },
  setStillwaterPolicyCd (state, payload) {
    state.stillwaterPolicyCd = payload
  },
  setRentalLosses (state, payload) {
    state.rentalLosses = payload
  },
  setRentersQuoteWarning (state, payload) {
    state.rentersQuoteWarning = payload
  },
  setRentalConstruction (state, payload) {
    state.rentalConstruction = payload
  },
  setRenterSSN (state, payload) {
    state.renter.ssn = payload
  }
}
const actions = {
  setRenterSSN ({ commit, getters }, payload) {
    commit('setRenterSSN', payload)
    console.log(getters.getRenter)
  },
  setRentalConstruction ({ commit }, payload) {
    commit('setRentalConstruction', payload)
  },
  setRentalLosses ({ commit }, payload) {
    commit('setRentalLosses', payload)
  },
  setRentalTransactionDetails ({ commit }, payload) {
    commit('setRentalTransactionDetails', payload)
  },
  setRentalAddress ({ commit }, payload) {
    commit('setRentalAddress', payload)
  },
  setRentalBldgProtection ({ commit }, payload) {
    commit('setRentalBldgProtection', payload)
  },
  setRentalAnswers ({ commit }, payload) {
    commit('setRentalAnswers', payload)
  },
  clearCustomRentalQuote ({ commit }) {
    commit('clearCustomRentalQuote')
  },
  setRentalQuote ({ commit }, payload) {
    commit('setRentalQuote', payload)
  },
  setRenter ({ commit }, payload) {
    commit('setRenter', payload)
  },
  propertyLookup ({ commit }, payload) {
    commit('setLoading', true)
    let rec = {
      signonRq: {
        custLangPref: 'ENG',
      },
      addr: {
        addr1: payload.addr1,
        city: payload.city,
        stateProvCd: payload.stateProvCd,
        postalCode: payload.postalCode,
      },
    }
    console.log(rec)
    let propertyLookup = firebase.functions().httpsCallable('propertyLookup')
    return propertyLookup(rec)
      .then((data) => {
        let resp = data.data.response.data
        console.log('Stillwater property lookup response ', resp)
        console.log(resp)
        commit('setPropertyData', resp)
      })
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
      })
  },
  async getRentersQuote ({ commit }, payload) {
    console.log('Calling renters quote', payload)
    commit('setLoading', true)
    commit('setRentersQuoteWarning', null)
    const { renter } = payload
    const {
      mailingAddress,
      previousAddress,
      secondOwner,
      existingPolicyInfo,
      additionalInterest,
      address,
      propertyCode,
    } = renter
    const addresses = [mailingAddress, previousAddress].filter(Boolean)

    const generateInsuredOrPrincipal = (owner, roleCode) => ({
      generalPartyInfo: {
        nameInfo: {
          personName: {
            surname: owner.lastname,
            givenName: owner.firstname,
          },
          taxIdentity: {
            taxIdTypeCd: 'SSN',
            taxId: owner.ssn,
          }
        },
        communications: {
          emailInfo: {
            emailAddr: renter.email,
          },
        },
        addresses,
      },
      insuredOrPrincipalInfo: {
        insuredOrPrincipalRoleCd: roleCode,
        personInfo: {
          birthDt: owner.dob,
        },
      },
    })

    let rec = {
      nonCA: renter.nonCA,
      signonRq: {
        custLangPref: 'ENG',
      },
      insuranceSvcRq: {
        quoteOrBuy: 'Q',
        homePolicyRq: {
          curCd: 'USD',
          insuredOrPrincipals: secondOwner
            ? [
                generateInsuredOrPrincipal(renter, 'FNI'),
                generateInsuredOrPrincipal(secondOwner, 'AI'),
              ]
            : [generateInsuredOrPrincipal(renter, 'FNI')],
          persPolicy: {
            LOBCd: 'HOME',
            // contractTerm: {
            //   effectiveDt: '2023-07-15',
            //   expirationDt: '2024-07-15'
            // },
            persApplicationInfo: {
              residenceOwnedRentedCd: 'RENTD',
            },
            numLosses: 0,
            ...(existingPolicyInfo && {
              otherOrPriorPolicies: [existingPolicyInfo],
            }),
          },
          location: {
            id: 'LOC1',
            addr: {
              addrTypeCd: 'PhysicalRisk',
              addr1: address.addr1,
              city: address.city,
              stateProvCd: address.stateProvCd,
              postalCode: address.postalCode,
            },
            ...(additionalInterest && { additionalInterest }),
          },
          homeLineBusiness: {
            LOBCd: 'HOME',
            dwell: {
              id: propertyCode + '1',
              locationRef: 'LOC1',
              policyTypeCd: '04',
              dwellOccupancy: {
                residenceTypeCd: propertyCode,
                occupancyTypeCd: 'TENAN',
              },
              coverages: [
                {
                  coverageCd: 'PP',
                  limits: [
                    {
                      formatCurrencyAmt: {
                        amt: payload.amt,
                      },
                    },
                  ],
                  deductible: {
                    formatInteger: payload.deductible,
                    deductibleAppliesToCd: 'AllPeril',
                  },
                },
              ],
            },
          },
        },
      },
    }
    console.log(rec)
    try {
      let getRentersQuote = firebase.functions().httpsCallable('rentersQuote')
      const data = await getRentersQuote(rec)
      console.log(data)

      const hasError =
        data.data.response.haserror === undefined
          ? data.data.response[0].haserror
          : data.data.response.haserror

      if (hasError === true) {
        console.log('get renters quote error')
        commit(
          'setError',
          'Unable to process request: ' + data.data.response[0].message
        )
        return
      }

      let resp = data.data.response.data
      const response = data?.data?.response
      const msgStatusDesc =
        response?.data?.ACORD?.InsuranceSvcRs[0]?.HomePolicyQuoteInqRs[0]
          ?.MsgStatus?.[0]?.MsgStatusDesc?.[0] ?? ''

      if (msgStatusDesc !== undefined && msgStatusDesc.includes('WARNING')) {
        console.log('warning')
        const warningMessage =
          'WARNING - This risk will be quoted Excluding Wind/Hail coverage. Do not continue if you require Wind/Hail coverage.'
        commit('setRentersQuoteWarning', warningMessage)
      }

      const typeMappings = {
        small: {
          quote: 'setSmallRentalQuote',
          quoteNumber:
            resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
              .QuoteInfo[0].CompanysQuoteNumber[0],
        },
        medium: {
          quote: 'setMediumRentalQuote',
          quoteNumber:
            resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
              .QuoteInfo[0].CompanysQuoteNumber[0],
        },
        large: {
          quote: 'setLargeRentalQuote',
          quoteNumber:
            resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
              .QuoteInfo[0].CompanysQuoteNumber[0],
        },
        custom: {
          quote: 'setCustomRentalQuote',
          quoteNumber:
            resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
              .QuoteInfo[0].CompanysQuoteNumber[0],
        },
      }

      if (payload.type in typeMappings) {
        const { quote } = typeMappings[payload.type]
        commit(quote, resp)
      }

      commit('setLoading', false)
    } catch (error) {
      commit('setError', 'Unable to process request')
      console.log(error)
      commit('setLoading', false)
    }
  },
  async getRentersQuoteWithAnswers ({ commit, getters }, payload) {
    let renter = getters.getRenter
    let losses = getters.getRentalLosses
    let numLosses = losses.length
    let mailingAddress = renter.mailingAddress
    let previousAddress = renter.previousAddress
    let construction = getters.getRentalConstruction
    let maritalStatusCd = payload.maritalStatus
    let startDate = payload.startDate
    console.log(payload.bldgProtection)
    console.log('Calling renters quote with survey answers', payload)
    commit('setLoading', true)
    let rec = {
      nonCA: renter.nonCA,
      signonRq: {
        custLangPref: 'ENG',
      },
      insuranceSvcRq: {
        quoteOrBuy: 'Q',
        homePolicyRq: {
          curCd: 'USD',
          insuredOrPrincipals: renter.secondOwner
            ? [
                {
                  generalPartyInfo: {
                    nameInfo: {
                      personName: {
                        surname: renter.lastname,
                        givenName: renter.firstname,
                      },
                      taxIdentity: {
                        taxIdTypeCd: 'SSN',
                        taxId: renter.ssn,
                      }
                    },
                    communications: {
                      emailInfo: {
                        emailAddr: renter.email,
                      },
                    },
                    addresses:
                      mailingAddress && previousAddress
                        ? [mailingAddress, previousAddress]
                        : mailingAddress && !previousAddress
                        ? [mailingAddress]
                        : !mailingAddress && previousAddress
                        ? [previousAddress]
                        : [],
                  },
                  insuredOrPrincipalInfo: {
                    insuredOrPrincipalRoleCd: 'FNI',
                    personInfo: maritalStatusCd
                      ? {
                          birthDt: renter.dob,
                          maritalStatusCd: maritalStatusCd,
                        }
                      : {
                          birthDt: renter.dob,
                        },
                  },
                },
                {
                  generalPartyInfo: {
                    nameInfo: {
                      personName: {
                        surname: renter.secondOwner.lastname,
                        givenName: renter.secondOwner.firstname,
                      },
                    },
                    addresses:
                      mailingAddress && previousAddress
                        ? [mailingAddress, previousAddress]
                        : mailingAddress && !previousAddress
                        ? [mailingAddress]
                        : !mailingAddress && previousAddress
                        ? [previousAddress]
                        : [],
                  },
                  insuredOrPrincipalInfo: {
                    insuredOrPrincipalRoleCd: 'AI',
                    personInfo: {
                      birthDt: renter.secondOwner.dob,
                    },
                  },
                },
              ]
            : [
                {
                  generalPartyInfo: {
                    nameInfo: {
                      personName: {
                        surname: renter.lastname,
                        givenName: renter.firstname,
                      },
                      taxIdentity: {
                        taxIdTypeCd: 'SSN',
                        taxId: renter.ssn,
                      }
                    },
                    communications: {
                      emailInfo: {
                        emailAddr: renter.email,
                      },
                    },
                    addresses:
                      mailingAddress && previousAddress
                        ? [mailingAddress, previousAddress]
                        : mailingAddress && !previousAddress
                        ? [mailingAddress]
                        : !mailingAddress && previousAddress
                        ? [previousAddress]
                        : [],
                  },
                  insuredOrPrincipalInfo: {
                    insuredOrPrincipalRoleCd: 'FNI',
                    personInfo: maritalStatusCd
                      ? {
                          birthDt: renter.dob,
                          maritalStatusCd: maritalStatusCd,
                        }
                      : {
                          birthDt: renter.dob,
                        },
                  },
                },
              ],
          persPolicy: renter.existingPolicyInfo
            ? {
                otherOrPriorPolicies: [renter.existingPolicyInfo],
                LOBCd: 'HOME',
                contractTerm: {
                  effectiveDt: startDate,
                },
                persApplicationInfo: {
                  residenceOwnedRentedCd: 'RENTD',
                },
                numLosses: losses === undefined ? 0 : numLosses,
                losses: losses === undefined ? [] : losses,
              }
            : {
                LOBCd: 'HOME',
                contractTerm: {
                  effectiveDt: startDate,
                },
                persApplicationInfo: {
                  residenceOwnedRentedCd: 'RENTD',
                },
                numLosses: losses === undefined ? 0 : numLosses,
                losses: losses === undefined ? [] : losses,
              },
          location: renter.additionalInterest
            ? {
                id: 'LOC1',
                addr: {
                  addrTypeCd: 'PhysicalRisk',
                  addr1: renter.address.addr1,
                  city: renter.address.city,
                  stateProvCd: renter.address.stateProvCd,
                  postalCode: renter.address.postalCode,
                },
                additionalInterest: renter.additionalInterest,
              }
            : {
                id: 'LOC1',
                addr: {
                  addrTypeCd: 'PhysicalRisk',
                  addr1: renter.address.addr1,
                  city: renter.address.city,
                  stateProvCd: renter.address.stateProvCd,
                  postalCode: renter.address.postalCode,
                },
              },
          homeLineBusiness: {
            LOBCd: 'HOME',
            dwell: {
              id: renter.propertyCode + '1',
              locationRef: 'LOC1',
              policyTypeCd: '04',
              construction: construction,
              dwellOccupancy: {
                residenceTypeCd: renter.propertyCode,
                occupancyTypeCd: 'TENAN',
              },
              bldgProtection: {
                fireExtinguisherInd: payload.bldgProtection.fireExtinguisherInd,
                protectionDeviceFireCd:
                  payload.bldgProtection.protectionDeviceFireCd,
                protectionDeviceSmokeCd:
                  payload.bldgProtection.protectionDeviceSmokeCd,
                protectionDeviceBurglarCd:
                  payload.bldgProtection.protectionDeviceBurglarCd,
                protectionDeviceSprinklerCd: payload.bldgProtection
                  .protectionDeviceSprinklerCd
                  ? payload.bldgProtection.protectionDeviceSprinklerCd
                  : 'NO',
                doorLockCd: payload.bldgProtection.doorLockCd,
              },
              coverages: [
                {
                  coverageCd: 'PP',
                  limits: [
                    {
                      formatCurrencyAmt: {
                        amt: payload.amt,
                      },
                    },
                  ],
                  deductible: {
                    formatInteger: payload.deductible,
                    deductibleAppliesToCd: 'AllPeril',
                  },
                },
              ],
              questionAnswers: payload.questionAnswers,
            },
          },
        },
      },
    }
    console.log(rec)
    let getRentersQuote = firebase.functions().httpsCallable('rentersQuote')
    return getRentersQuote(rec)
      .then((data) => {
        console.log(data)
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          console.log('get renters quote error')
          commit(
            'setError',
            'Unable to process request: ' + data.data.response[0].message
          )
          commit('setLoading', false)
          return
        }
        let discounts = []
        let resp = data.data.response.data
        console.log(resp, 'RESPONSE')
        const msgStatusDesc =
          resp?.ACORD?.InsuranceSvcRs?.[0]?.HomePolicyQuoteInqRs?.[0]?.MsgStatus?.[0]?.MsgStatusDesc?.[0]
        console.log(msgStatusDesc)
        if (msgStatusDesc !== undefined && msgStatusDesc.includes('WARNING')) {
          console.log('warning')
          const warningMessage =
            'This quote excludes wind/hail coverage. Please contact us (link to contact us page) with any questions or if you require additional support.'
          commit('setRentersQuoteWarning', warningMessage)
        }
        console.log('RESP DATA', resp)
        console.log('RESP DATA', resp)
        let coverages =
          resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage
        console.log('coverages', coverages)
        coverages.forEach((coverage) => {
          if (
            coverage.CurrentTermAmt &&
            coverage.CurrentTermAmt[0].Amt[0].includes('-')
          ) {
            discounts.push(coverage)
            console.log('discount found')
          }
        })
        commit('setRentalDiscounts', discounts)
        console.log(discounts)
        commit('setRentalQuote', resp)
        commit(
          'setRentalQuoteNumber',
          resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
            .QuoteInfo[0].CompanysQuoteNumber[0]
        )
        console.log(
          'renters quote number',
          resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
            .QuoteInfo[0].CompanysQuoteNumber[0]
        )
        commit('setLoading', false)
      })

      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },
  async getRevisedRentersQuote ({ commit, getters }, payload) {
    let renter = getters.getRenter
    let mailingAddress = renter.mailingAddress
    let previousAddress = renter.previousAddress
    let bldgPro = getters.getRentalBldgProtection
    let qa = getters.getRentalAnswers
    let losses = getters.getRentalLosses
    let construction = getters.getRentalConstruction
    let maritalStatusCd = payload.maritalStatus
    console.log('losses', losses)
    console.log('lossses after undefined check', losses)
    let numLosses = losses.length
    commit('setLoading', true)
    let rec = {
      nonCA: renter.nonCA,
      signonRq: {
        custLangPref: 'ENG',
      },
      insuranceSvcRq: {
        quoteOrBuy: 'Q',
        homePolicyRq: {
          curCd: 'USD',
          insuredOrPrincipals: renter.secondOwner
            ? [
                {
                  generalPartyInfo: {
                    nameInfo: {
                      personName: {
                        surname: renter.lastname,
                        givenName: renter.firstname,
                      },
                      taxIdentity: {
                        taxIdTypeCd: 'SSN',
                        taxId: renter.ssn,
                      }
                    },
                    communications: {
                      emailInfo: {
                        emailAddr: renter.email,
                      },
                    },
                    addresses:
                      mailingAddress && previousAddress
                        ? [mailingAddress, previousAddress]
                        : mailingAddress && !previousAddress
                        ? [mailingAddress]
                        : !mailingAddress && previousAddress
                        ? [previousAddress]
                        : [],
                  },
                  insuredOrPrincipalInfo: {
                    insuredOrPrincipalRoleCd: 'FNI',
                    personInfo: maritalStatusCd
                      ? {
                          birthDt: renter.dob,
                          maritalStatusCd: maritalStatusCd,
                        }
                      : {
                          birthDt: renter.dob,
                        },
                  },
                },
                {
                  generalPartyInfo: {
                    nameInfo: {
                      personName: {
                        surname: renter.secondOwner.lastname,
                        givenName: renter.secondOwner.firstname,
                      },
                    },
                    addresses:
                      mailingAddress && previousAddress
                        ? [mailingAddress, previousAddress]
                        : mailingAddress && !previousAddress
                        ? [mailingAddress]
                        : !mailingAddress && previousAddress
                        ? [previousAddress]
                        : [],
                  },
                  insuredOrPrincipalInfo: {
                    insuredOrPrincipalRoleCd: 'AI',
                    personInfo: {
                      birthDt: renter.secondOwner.dob,
                    },
                  },
                },
              ]
            : [
                {
                  generalPartyInfo: {
                    nameInfo: {
                      personName: {
                        surname: renter.lastname,
                        givenName: renter.firstname,
                      },
                      taxIdentity: {
                        taxIdTypeCd: 'SSN',
                        taxId: renter.ssn,
                      }
                    },
                    communications: {
                      emailInfo: {
                        emailAddr: renter.email,
                      },
                    },
                    addresses:
                      mailingAddress && previousAddress
                        ? [mailingAddress, previousAddress]
                        : mailingAddress && !previousAddress
                        ? [mailingAddress]
                        : !mailingAddress && previousAddress
                        ? [previousAddress]
                        : [],
                  },
                  insuredOrPrincipalInfo: {
                    insuredOrPrincipalRoleCd: 'FNI',
                    personInfo: {
                      birthDt: renter.dob,
                    },
                  },
                },
              ],
          persPolicy: renter.existingPolicyInfo
            ? {
                otherOrPriorPolicies: [renter.existingPolicyInfo],
                LOBCd: 'HOME',
                persApplicationInfo: {
                  residenceOwnedRentedCd: 'RENTD',
                },
                numLosses: losses === undefined ? 0 : numLosses,
                losses: losses === undefined ? [] : losses,
              }
            : {
                LOBCd: 'HOME',
                persApplicationInfo: {
                  residenceOwnedRentedCd: 'RENTD',
                },
                numLosses: losses === undefined ? 0 : numLosses,
                losses: losses === undefined ? [] : losses,
              },
          location: renter.additionalInterest
            ? {
                id: 'LOC1',
                addr: {
                  addrTypeCd: 'PhysicalRisk',
                  addr1: renter.address.addr1,
                  city: renter.address.city,
                  stateProvCd: renter.address.stateProvCd,
                  postalCode: renter.address.postalCode,
                },
                additionalInterest: renter.additionalInterest,
              }
            : {
                id: 'LOC1',
                addr: {
                  addrTypeCd: 'PhysicalRisk',
                  addr1: renter.address.addr1,
                  city: renter.address.city,
                  stateProvCd: renter.address.stateProvCd,
                  postalCode: renter.address.postalCode,
                },
              },
          homeLineBusiness: {
            LOBCd: 'HOME',
            dwell: {
              id: renter.propertyCode + '1',
              locationRef: 'LOC1',
              policyTypeCd: '04',
              dwellOccupancy: {
                residenceTypeCd: renter.propertyCode,
                occupancyTypeCd: 'TENAN',
              },
              construction: construction,
              bldgProtection: {
                fireExtinguisherInd: bldgPro.fireExtinguisherInd,
                protectionDeviceFireCd: bldgPro.protectionDeviceFireCd,
                protectionDeviceSmokeCd: bldgPro.protectionDeviceSmokeCd,
                protectionDeviceBurglarCd: bldgPro.protectionDeviceBurglarCd,
                doorLockCd: bldgPro.doorLockCd,
                protectionDeviceSprinklerCd: bldgPro.protectionDeviceSprinklerCd
                  ? bldgPro.protectionDeviceSprinklerCd
                  : 'NO',
              },
              coverages: payload,
              questionAnswers: qa,
            },
          },
        },
      },
    }
    console.log('Calling revised renters quote', rec)
    let getRevisedRentersQuote = firebase
      .functions()
      .httpsCallable('rentersQuote')
    return getRevisedRentersQuote(rec)
      .then((data) => {
        console.log(data)
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          console.log('get revised renters quote error')
          commit(
            'setError',
            'Unable to process request: ' + data.data.response[0].message
          )
          commit('setLoading', false)
          return
        }
        let resp = data.data.response.data
        console.log('Revised quote', resp)
        commit('setRentalQuote', resp)
        commit(
          'setRentalQuoteNumber',
          resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
            .QuoteInfo[0].CompanysQuoteNumber[0]
        )
        console.log(
          'renters quote number',
          resp.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0]
            .QuoteInfo[0].CompanysQuoteNumber[0]
        )
        commit('setLoading', false)
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },
  async rentersPreBind ({ commit, getters }, payload) {
    let renter = getters.getRenter
    let mailingAddress = renter.mailingAddress
    let previousAddress = renter.previousAddress
    commit('setLoading', true)
    console.log('Calling renters prebind')
    const createInsuredOrPrincipal = (person) => {
      return {
        generalPartyInfo: {
          nameInfo: {
            personName: {
              surname: person.lastname,
              givenName: person.firstname,
            },
          },
          communications: {
            emailInfo: {
              emailAddr: renter.email,
            },
          },
          addresses: [
            ...(mailingAddress && previousAddress
              ? [mailingAddress, previousAddress]
              : []),
            ...(mailingAddress && !previousAddress ? [mailingAddress] : []),
            ...(previousAddress && !mailingAddress ? [previousAddress] : []),
          ],
        },
        insuredOrPrincipalInfo: {
          insuredOrPrincipalRoleCd: person === renter ? 'FNI' : 'AI',
          personInfo: {
            birthDt: person.dob,
          },
        },
      }
    }
    let rec = {
      nonCA: renter.nonCA,
      signonRq: {
        custLangPref: 'ENG',
      },
      insuranceSvcRq: {
        quoteOrBuy: 'Q',
        homePolicyRq: {
          curCd: 'USD',
          isPreBind: 1,
          insuredOrPrincipals: renter.secondOwner
            ? [
                createInsuredOrPrincipal(renter),
                createInsuredOrPrincipal(renter.secondOwner),
              ]
            : [createInsuredOrPrincipal(renter)],
          persPolicy: {
            LOBCd: 'HOME',
            quoteInfo: {
              companysQuoteNumber: getters.getRentalQuoteNumber,
            },
          },
          location: renter.additionalInterest
            ? {
                id: 'LOC1',
                addr: {
                  addrTypeCd: 'PhysicalRisk',
                  addr1: renter.address.addr1,
                  city: renter.address.city,
                  stateProvCd: renter.address.stateProvCd,
                  postalCode: renter.address.postalCode,
                },
                additionalInterest: renter.additionalInterest,
              }
            : {
                id: 'LOC1',
                addr: {
                  addrTypeCd: 'PhysicalRisk',
                  addr1: renter.address.addr1,
                  city: renter.address.city,
                  stateProvCd: renter.address.stateProvCd,
                  postalCode: renter.address.postalCode,
                },
              },
          homeLineBusiness: {
            LOBCd: 'HOME',
            dwell: {},
          },
        },
      },
    }
    console.log(rec)
    let rentersPreBind = firebase.functions().httpsCallable('rentersPreBind')
    return rentersPreBind(rec)
      .then((data) => {
        if (
          (data.data.response.haserror === undefined &&
            data.data.response[0].haserror === true) ||
          (!(data.data.response.haserror === undefined) &&
            data.data.response.haserror === true)
        ) {
          console.log('get renters quote error')
          commit(
            'setError',
            'Unable to process request: ' + data.data.response[0].message
          )
          commit('setLoading', false)
          return
        }
        console.log('RESULT ', data)
        commit('setLoading', false)
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },
  async rentersBindCC ({ commit, getters }, payload) {
    commit('setLoading', true)
    const renter = getters.getRenter
    let billingAddress = payload.addr
    let mailingAddress = renter.mailingAddress
    let previousAddress = renter.previousAddress
    const policyData = getters.getRentalQuote
    const userkey = getters.getuser.id

    const createInsuredOrPrincipal = (person) => {
      return {
        generalPartyInfo: {
          nameInfo: {
            personName: {
              surname: person.lastname,
              givenName: person.firstname,
            },
          },
          communications: {
            emailInfo: {
              emailAddr: renter.email,
            },
          },
          addresses: [
            billingAddress,
            ...(mailingAddress && previousAddress
              ? [mailingAddress, previousAddress]
              : []),
            ...(mailingAddress && !previousAddress ? [mailingAddress] : []),
            ...(previousAddress && !mailingAddress ? [previousAddress] : []),
          ],
        },
        insuredOrPrincipalInfo: {
          insuredOrPrincipalRoleCd: person === renter ? 'FNI' : 'AI',
          personInfo: {
            birthDt: person.dob,
          },
        },
      }
    }

    let rec = {
      policyData: policyData,
      userkey: userkey,
      nonCA: renter.nonCA,
      signonRq: {
        custLangPref: 'ENG',
      },
      insuranceSvcRq: {
        quoteOrBuy: 'B',
        homePolicyRq: {
          transactionEffectiveDt: moment().format('YYYY-MM-DD'),
          curCd: 'USD',
          insuredOrPrincipals: renter.secondOwner
            ? [
                createInsuredOrPrincipal(renter),
                createInsuredOrPrincipal(renter.secondOwner),
              ]
            : [createInsuredOrPrincipal(renter)],
          persPolicy: {
            LOBCd: 'HOME',
            paymentOption: payload.paymentOption,
            quoteInfo: {
              companysQuoteNumber: getters.getRentalQuoteNumber,
            },
            questionAnswers: [
              {
                qCode: 'com.stillwater_AcknowledgementStatement',
                answer: 'YES',
                id: '100',
              },
            ],
            persApplicationInfo: {
              addr: billingAddress,
            },
          },
          homeLineBusiness: {
            LOBCd: 'HOME',
            dwell: {
              id: 'DWE1',
            },
          },
        },
      },
    }
    console.log('calling renders bind cc', rec)
    const rentersBind = firebase.functions().httpsCallable('rentersBind')
    return rentersBind(rec)
      .then((data) => {
        const hasError =
          data.data.response?.haserror ?? data.data.response[0]?.haserror
        if (hasError === true) {
          console.log('get renters quote error')
          commit(
            'setError',
            'Unable to process request: ' + data.data.response[0]?.message
          )
        } else {
          console.log('BIND RESULT cc ', data)
          commit('setRentalPolicy', data.data.response.data)
        }
        commit('setLoading', false)
      })
      .catch((error) => {
        commit('setError', 'Unable to process request')
        console.log(error)
        commit('setLoading', false)
      })
  },
  async getH4RentalQuestions ({ commit }, payload) {
    console.log(payload)
    // reset
    commit('setRentalSurveyQuestions', [])
    let propType = payload.propertyType
    if (propType === 'Single Family Home') {
      propType = 'Dwelling'
    }
    return firebase
      .database()
      .ref('stillwater')
      .child('questions')
      .child(payload.state)
      .child('H4')
      .child(propType)
      .once('value')
      .then((data) => {
        console.log(data.val())
        let values = Object.values(data.val())
        console.log(values)
        commit('setRentalSurveyQuestions', values)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getH4DiscountQuestions ({ commit }, payload) {
    console.log(payload)
    console.log(payload.propertyUse)
    commit('setRentalDiscountQuestions', [])
    let propType = payload.propertyType
    if (propType === 'Single Family Home') {
      propType = 'Dwelling'
    }
    return firebase
      .database()
      .ref('stillwater')
      .child('discount')
      .child(payload.state)
      .child('H4')
      .child(propType)
      .once('value')
      .then((data) => {
        console.log('Discount Questions', data.val())
        let values = Object.values(data.val())
        // Remove duplicates based on the id property
        let uniqueValues = Array.from(
          new Set(values.map((value) => value.id))
        ).map((id) => values.find((value) => value.id === id))
        commit('setRentalDiscountQuestions', uniqueValues)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getPropertyTypes ({ commit }) {
    commit('setPropertyTypes', [])
    return firebase
      .database()
      .ref('stillwater')
      .child('refdata')
      .child('Residence')
      .once('value')
      .then((data) => {
        console.log(data.val())
        let values = Object.values(data.val())
        console.log(values)
        commit('setPropertyTypes', values)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getDwellUseTypes ({ commit }) {
    commit('setDwellUseTypes', [])
    return firebase
      .database()
      .ref('stillwater')
      .child('refdata')
      .child('Dwell')
      .once('value')
      .then((data) => {
        console.log(data.val())
        let values = Object.values(data.val())
        console.log('Dwell Use Types', values)
        commit('setDwellUseTypes', values)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getStillwaterInterestTypes ({ commit }) {
    commit('setStillwaterInterestTypes', [])
    return firebase
      .database()
      .ref('stillwater')
      .child('refdata')
      .child('Interest')
      .once('value')
      .then((data) => {
        console.log(data.val())
        let values = Object.values(data.val())
        console.log('Interest Types', values)
        commit('setStillwaterInterestTypes', values)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getStillwaterLOB ({ commit }) {
    commit('setStillwaterLOB', [])
    return firebase
      .database()
      .ref('stillwater')
      .child('refdata')
      .child('LOB')
      .once('value')
      .then((data) => {
        console.log(data.val())
        let values = Object.values(data.val())
        console.log('LOB', values)
        commit('setStillwaterLOB', values)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getStillwaterPolicyCd ({ commit }) {
    commit('setStillwaterPolicyCd', [])
    return firebase
      .database()
      .ref('stillwater')
      .child('refdata')
      .child('PolicyCd')
      .once('value')
      .then((data) => {
        console.log(data.val())
        let values = Object.values(data.val())
        console.log('PolicyCd', values)
        commit('setStillwaterPolicyCd', values)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async loadUserPoliciesStillwaterRenters ({ commit, state }, payload) {
    try {
      const policies = []

      const { userkey } = payload
      const snapshot = await firebase
        .database()
        .ref(`users/${userkey}/policies/stillwater`)
        .once('value')
      snapshot.forEach((childSnapshot) => {
        const policy = childSnapshot.val()
        if (policy.policyTypeCd === '04') {
          policies.push({
            ...policy,
            key: childSnapshot.key,
          })
        }
      })

      commit('setRentalPolicies', policies)
    } catch (error) {
      console.error(error)
    }
  },
  async loadRentersDocuments ({ commit, getters }, payload) {
    try {
      commit('setLoading', true)
      const qNum = getters.getRentalQuoteNumber
      commit('setRentersPdfs', []) // reset

      const storageRef = firebase
        .storage()
        .ref()
        .child('stillwater')
        .child('documents')
        .child(qNum)

      const results = await storageRef.listAll()

      if (results.items.length === 0) {
        commit('setError', 'No documents found')
        return
      }

      let sortedItems = results.items.sort((a, b) => a - b)
      const promises = sortedItems.map(item => item.getDownloadURL())
      const pdfUrls = await Promise.all(promises)

      commit('setRentersPdfs', pdfUrls)
    } catch (error) {
      commit('setError', 'Unable to process request')
    } finally {
      commit('setLoading', false)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
