import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import tugoData from './modules/tugoPlanData'
import afficiency from './modules/afficiency'
import stillwaterHomeData from './modules/stillwaterHomeData'
import stillwaterCaliforniaQuestions from './modules/stillwaterCaliforniaQuestions'
import stillwaterRenters from './modules/stillwater/stillwaterRenters'
import borderfree from './modules/borderfree'
import stillwaterHome from './modules/stillwater/stillwaterHome'
import comms from './modules/comms'
import accounts from './modules/accounts'
import documents from './modules/documents'
import stillwaterCondo from './modules/stillwater/stillwaterCondo'
import canadianTermLife from './modules/canadianTermLife'
import verifyEmail from './modules/verifyEmail'
import customerQuote from './modules/customerQuote'
import addressValidation from './modules/addressValidation'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
if (process.env.NODE_ENV === 'development') { Vue.config.devtools = true }

export default new Vuex.Store({
  state: {
    prefferedLang: '',
    loginerror: null,
    insuredPersons: [],
    purchaseerror: '',
    isPolicyPurchased: false,
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: false,
    error: '',
    networkError: false,
    anicoTermlifeInterview: '',
    providerrecommendations: ['Symetra'],
    termquestions: [],
    symetraReplacementData: [],
    symetraAppQuestions: [],
    symetraOccupations: [],
    symetraMedicalFollowups: [
      // { label: 'weight change', followups: ['weight change 1', 'weight change 2', 'weight change 3'] },
      {
        label: 'heart disease',
        followups: ['Angina',
          'Cardiomyopathy',
          'Chest pain',
          'Congential cardiac disease',
          'Coronary artery disease',
          'Heart attack or myocardial infarction',
          'Heart murmur',
          'Heart valve disease',
          'High blood pressure',
          'Implanted pacemaker or cardioverter defibrillator',
          'Irregular heartbeat or abnormal heart rhythm (arrhythmia)']
      },
      {
        label: 'diabetes',
        followups: ['Elevated cholesterol',
          'Elevated triglycerides',
          'Gestational diabetes',
          'Glucose impairment or prediabetes',
          'High blood sugar',
          'Hyperlipidemia',
          'Hypothyroidism',
          'Polycystic ovarian syndrome (PCOS)',
          'Thyroid disorder',
          'Thyroid nodule',
          'Type 1 diabetes',
          'Type 2 diabetes']
      },
      {
        label: 'blood',
        followups: ['Anemia',
          'Embolism',
          'Hemochromatosis',
          'Hemophilia',
          'Sickle cell disease',
          'Thrombocytosis',
          'Thrombosis']
      },
      {
        label: 'lung',
        followups: ['Allergies',
          'Asthma',
          'Chronic bronchitis',
          'Chronic obstructive pulmonary disease (COPD)',
          'Cystic fibrosis',
          'Emphysema',
          'Narcolepsy',
          'Pneumonia',
          'Sarcoidosis',
          'Sleep apnea',
          'Tuberculosis (TB)',
          'Upper respiratory tract infection']
      },
      {
        label: 'mental',
        followups: ['Anxiety',
          'Attention deficit hyperactivity disorder or attention deficit disorder',
          'Bipolar',
          'Depression',
          'Eating disorder',
          'Post traumatic stress disorder (PTSD)',
          'Psychosis',
          'Schizophrenia',
          'Suicide attempt']
      },
      {
        label: 'cancer',
        followups: ['Basal cell carcinoma',
          'Brain cancer, tumor or mass',
          'Breast cancer',
          'Colon cancer',
          "Hodgkin's disease",
          'Leukemia',
          'Lung cancer',
          'Lymphoma',
          'Melanoma',
          'Multiple myeloma',
          'Myeloproliferative disorder or blood cancer',
          'Ovarian cancer',
          'Polyp(s)',
          'Prostate cancer',
          'Squamous cell carcinoma']
      },
      {
        label: 'stomach',
        followups: ["Barrett's esophagus",
          'Cirrhosis of the liver',
          "Crohn's disease",
          'Diverticulitis',
          'Gastrointestinal bleeding',
          'Hepatitis',
          'Jaundice',
          'Pancreatitis',
          'Ulcerative colitis',
          'Weight loss surgery']
      },
      {
        label: 'brain',
        followups: ["Alzheimer's or dementia",
          'Amyotrophic lateral sclerosis (ALS)',
          'Cerebral palsy',
          'Dizziness',
          'Encephalitis',
          'Epilepsy or seizure',
          'Fainting',
          "Huntington's disease",
          'Intellectual disability or Down syndrome',
          'Memory loss',
          'Meningitis',
          'Multiple sclerosis (MS)',
          'Muscular dystrophy',
          'Myasthenia gravis',
          'Paralysis',
          "Parkinson's disease",
          'Stroke or transient ischemic attack']
      },
      {
        label: 'bone',
        followups: ['Ankylosing spondylitis',
          'Arthritis',
          'Chronic fatigue syndrome',
          'Chronic pain',
          'Fibromyalgia',
          'Gout',
          'Lupus',
          'Mixed connective tissue disease',
          'Osteoarthritis',
          'Osteomyelitis',
          'Osteoporosis',
          'Psoriasis',
          'Rheumatoid arthritis (RA)',
          'Scleroderma']
      },
      // { label: 'doctor', followups: ['doctor 1', 'doctor 2', 'doctor 3'] },
      // { label: 'surgery', followups: ['surgery 1', 'surgery 2', 'surgery 3'] },
      // { label: 'prescription', followups: ['prescription 1', 'prescription 2', 'prescription 3'] },
      // { label: 'hiv', followups: ['hiv 1', 'hiv 2', 'hiv 3'] },
      // { label: 'history', followups: ['history 1', 'history 2', 'history 3'] },
      {
        label: 'breast kidney',
        followups: ['Abnormal pap smear',
          'Abnormal mammogram',
          'Abnormal PSA testing',
          'Kidney disorder',
          'Kidney or bladder stones',
          'Polycystic kidney disease (PKD)',
          'Polycystic ovarian syndrome (PCOS)',
          'Pregnancy complications',
          'Prostate disorder',
          'Protein or blood in urine',
          'Urinary tract infection']
      },
      {}
    ],
    anicoAppQuestions: [],
    petBillingPreview: {},
    petQuoteResponse: {},
    petInfoData: {},
    petPlanData: {},
    pets: [],
    petNotEligible: false,
    symetrafollowups: '',
    postcode: '',
    alerts: [],
    // user: '',
    user: null,
    activeUser: null,
    loggedInUser: null,
    currentUserKey: 'UNSET',
    locationdata: '',
    websiteLocation: '',
    lang: 'en',
    userIp: '',
    bmi: '',
    age: '',
    nic: '',
    eligibility: false,
    eligibilityData: '',
    eligibilityDetails: {},
    mobilestatus: false,
    emailstatus: false,
    compareList: [],
    applieddetails: '',
    followup: '',
    income: '',
    gender: '',
    smoker: '',
    usertermlifeclass: '',
    termLifeRates: '',
    postcodedata: '',
    userprofile: '',
    pdfloading: false,
    renderedApplicationsLoading: false,
    completedPdfLoading: false,
    readyforSignature: false,
    trupanionPaymentParams: '',
    symetraCompletedClientPdfs: [],
    woprates: '',
    adbrates: '',
    icbrates: '',
    partnerId: 'unknown',
    campaignId: 'unknown',
    blanketpartners: [],
    partnerCampaigns: [],
    isloading: false,
    userpolicies: [],
    travelpolicies: [],
    usatravelpolicies: [],
    petPolicies: [],
    symetraPdfFiles: '',
    completedSymetraPDFApplication: false,
    symetraICC20LA1: '',
    symetraICC20LA2: '',
    symetraICC20LA3: '',
    symetraLU820: '',
    quote: '',
    symetraAPIPath: 'http://localhost:3000',
    travelDetails: {
      destination: '',
      primaryDestination: '',
      living: '',
      primaryTraveler: '',
      secondTraveler: '',
      tripCost: '',
      coverage: '',
      numberOfTravelers: Number,
      startDate: '',
      endDate: '',
    },
    USATravelDetails: {
      destination: '',
      primaryDestination: '',
      living: '',
      primaryTraveler: '',
      secondTraveler: '',
      tripCost: '',
      coverage: '',
      numberOfTravelers: Number,
      startDate: '',
      endDate: '',
    },
    travelCoverageDetails: {
      plan: '',
      cost: '',
      carrier: '',
      premium: '',
      price: '',
      created: '',
      type: '',
      carrierRef: '',
      clientkey: '',
      productclass: '',
      numinsured: '',
      departdate: '',
      returndate: '',
      triptype: '',
      destination: '',
      tripcost: '',
      travelers: '',
      age: '',
    },
    canadaTravelProducts: '',
    canadaTravelQuote: '',
    canadaTravelPurchase: '',
    canadianTravelError: '',
    canadaTravelSurveys: '',
    canadaQuoteFinalPricing: '',
    tugoMedicalSurvey: {
      q1: '',
      q2: '',
      q3: '',
      q4: [],
      q5: [],
      q6: [],
      q7: [],
      q8: [],
      q9: [],
    },
    usaRentalPricePref: '',
    usaRentalPriceStd: '',
    rentalDamagePricePref: '',
    rentalDamagePriceStd: '',
    CFARCost: '',
    usaTravelCostPrem: '',
    usaTravelCostStd: '',
    usaTravelCostPref: '',
    usaCancellationCost: '',
    usaTravelQuote: '',
    usaTravelQuoteOptions: '',
    usaTravelPurchase: '',
    usaTravelErrors: '',
    travelUSAPolicy: '',
    DataSpyApplicants: [],
    generaliPolicy: {
      carrier: 'Generali',
      policyOwner: 'Bob Barker',
      price: '140.97',
      policyNumber: '4a7982453',
      startDate: '2022-02-01',
      endDate: '2022-02-16'
    },
    petPolicy: {},
    petbreeds: [{ SpeciesId: 1, Name: 'Best Dog Ever' }, { SpeciesId: 2, Name: 'Best Cat Ever' }],
    petages: ['1', '2'],
    petPrems: [],
    petPremiums: [
      {
        Premium: 1.84,
        Deductible: 0,
        CoinsurancePercent: 90,
        PolicyOptionCalculations: {
          RecoveryAndComplementaryCareRider: {
            Cost: 1.76
          },
          PetOwnerAssistanceRider: {
            Cost: 4.95
          }
        },
        NonDiscountedPremiumCalculation: {
          Premium: 19.84,
          PolicyOptionCalculations: {
            RecoveryAndComplementaryCareRider: {
              Cost: 1.76
            },
            PetOwnerAssistanceRider: {
              Cost: 4.95
            }
          }
        }
      },
      {
        Premium: 19.84,
        Deductible: 200,
        CoinsurancePercent: 90,
        PolicyOptionCalculations: {
          RecoveryAndComplementaryCareRider: {
            Cost: 1.76
          },
          PetOwnerAssistanceRider: {
            Cost: 4.95
          }
        },
        NonDiscountedPremiumCalculation: {
          Premium: 19.84,
          PolicyOptionCalculations: {
            RecoveryAndComplementaryCareRider: {
              Cost: 1.76
            },
            PetOwnerAssistanceRider: {
              Cost: 4.95
            }
          }
        }
      },
      {
        Premium: 77.00,
        Deductible: 700,
        CoinsurancePercent: 90,
        PolicyOptionCalculations: {
          RecoveryAndComplementaryCareRider: {
            Cost: 1.76
          },
          PetOwnerAssistanceRider: {
            Cost: 4.95
          }
        },
        NonDiscountedPremiumCalculation: {
          Premium: 19.84,
          PolicyOptionCalculations: {
            RecoveryAndComplementaryCareRider: {
              Cost: 1.76
            },
            PetOwnerAssistanceRider: {
              Cost: 4.95
            }
          }
        }
      },
      {
        Premium: 1.00,
        Deductible: 1000,
        CoinsurancePercent: 90,
        PolicyOptionCalculations: {
          RecoveryAndComplementaryCareRider: {
            Cost: 1.76
          },
          PetOwnerAssistanceRider: {
            Cost: 4.95
          }
        },
        NonDiscountedPremiumCalculation: {
          Premium: 19.84,
          PolicyOptionCalculations: {
            RecoveryAndComplementaryCareRider: {
              Cost: 1.76
            },
            PetOwnerAssistanceRider: {
              Cost: 4.95
            }
          }
        }
      },
    ],
    petCoverageDetails: {
      carrier: '',
      plan: '',
      price: ''
    },
    billingInfo: {
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      suite: '',
      city: '',
      state: '',
      zip: '',
    },
    petDetails: [],
    autoDetails: {
      effectiveDate: '',
      firstName: '',
      lastName: '',
      city: '',
      state: '',
      zip: '',
      suite: '',
      garagingAddress: '',
      dob: '',
      vehicleType: '',
      make: '',
      model: '',
      bodyStyle: '',
      vehicleUse: '',
    },
    policyTypeSummary: [
      {
        name: 'Travel',
        year: '2022',
        quarter: 'Q1',
        data: [44, 55, 41, 37, 22, 43, 21, 53, 32, 33]
      }, {
        name: 'Pet',
        year: '2022',
        quarter: 'Q1',
        data: [53, 32, 33, 52, 13, 43, 32, 12, 17, 11]
      }, {
        name: 'Renters',
        year: '2022',
        quarter: 'Q1',
        data: [12, 17, 11, 9, 15, 11, 20, 9, 7, 5]
      }, {
        name: 'Home',
        year: '2022',
        quarter: 'Q1',
        data: [9, 7, 5, 8, 6, 9, 4, 25, 12, 19]
      }, {
        name: 'Term Life',
        year: '2022',
        quarter: 'Q1',
        data: [25, 12, 19, 32, 25, 24, 10, 44, 55, 41]
      },
      {
        name: 'Travel',
        year: '2022',
        quarter: 'Q2',
        data: [25, 12, 19, 32, 25, 24, 10, 44, 55, 41]
      }, {
        name: 'Pet',
        year: '2022',
        quarter: 'Q2',
        data: [9, 7, 5, 8, 6, 9, 4, 25, 12, 19]
      }, {
        name: 'Renters',
        year: '2022',
        quarter: 'Q2',
        data: [12, 17, 11, 9, 15, 11, 20, 9, 7, 5]
      }, {
        name: 'Home',
        year: '2022',
        quarter: 'Q2',
        data: [53, 32, 33, 52, 13, 43, 32, 12, 17, 11]
      }, {
        name: 'Term Life',
        year: '2022',
        quarter: 'Q2',
        data: [44, 55, 41, 37, 22, 43, 21, 53, 32, 33]
      },
      {
        name: 'Travel',
        year: '2022',
        quarter: 'Q3',
        data: [44, 55, 41, 37, 22, 43, 21, 53, 32, 33]
      }, {
        name: 'Pet',
        year: '2022',
        quarter: 'Q3',
        data: [53, 32, 33, 52, 13, 43, 32, 12, 17, 11]
      }, {
        name: 'Renters',
        year: '2022',
        quarter: 'Q3',
        data: [12, 17, 11, 9, 15, 11, 20, 9, 7, 5]
      }, {
        name: 'Home',
        year: '2022',
        quarter: 'Q3',
        data: [9, 7, 5, 8, 6, 9, 4, 25, 12, 19]
      }, {
        name: 'Term Life',
        year: '2022',
        quarter: 'Q3',
        data: [25, 12, 19, 32, 25, 24, 10, 44, 55, 41]
      },
      {
        name: 'Travel',
        year: '2022',
        quarter: 'Q4',
        data: [25, 12, 19, 32, 25, 24, 10, 44, 55, 41]
      }, {
        name: 'Pet',
        year: '2022',
        quarter: 'Q4',
        data: [9, 7, 5, 8, 6, 9, 4, 25, 12, 19]
      }, {
        name: 'Renters',
        year: '2022',
        quarter: 'Q4',
        data: [12, 17, 11, 9, 15, 11, 20, 9, 7, 5]
      }, {
        name: 'Home',
        year: '2022',
        quarter: 'Q4',
        data: [53, 32, 33, 52, 13, 43, 32, 12, 17, 11]
      }, {
        name: 'Term Life',
        year: '2022',
        quarter: 'Q4',
        data: [44, 55, 41, 37, 22, 43, 21, 53, 32, 33]
      },
    ],
    policyQuarterSummary: [
      {
        year: '2022',
        quarter: 'Q1',
        data: [230, 310, 400, 1010, 400, 360, 320, 230, 140, 280]
      },
      {
        year: '2022',
        quarter: 'Q2',
        data: [310, 400, 1010, 400, 360, 320, 230, 140, 280, 230]
      },
      {
        year: '2022',
        quarter: 'Q3',
        data: [400, 1010, 400, 360, 320, 230, 140, 280, 230, 310]
      },
      {
        year: '2022',
        quarter: 'Q4',
        data: [1010, 400, 360, 320, 230, 140, 280, 230, 310, 400]
      }
    ],
    salesByType: [{
      name: 'Travel',
      year: '2022',
      quarter: 'Q1',
      data: [7]
    },
      {
        name: 'Travel',
        year: '2022',
        quarter: 'Q2',
        data: [11]
      },
      {
        name: 'Travel',
        year: '2022',
        quarter: 'Q3',
        data: [26]
      },
      {
        name: 'Travel',
        year: '2022',
        quarter: 'Q4',
        data: [25]
      },
      {
        name: 'Pet',
        year: '2022',
        quarter: 'Q1',
        data: [14]
      },
      {
        name: 'Pet',
        year: '2022',
        quarter: 'Q2',
        data: [32]
      },
      {
        name: 'Pet',
        year: '2022',
        quarter: 'Q3',
        data: [28]
      },
      {
        name: 'Pet',
        year: '2022',
        quarter: 'Q4',
        data: [12]
      },
      {
        name: 'Renters',
        year: '2022',
        quarter: 'Q1',
        data: [12]
      },
      {
        name: 'Renters',
        year: '2022',
        quarter: 'Q2',
        data: [17]
      },
      {
        name: 'Renters',
        year: '2022',
        quarter: 'Q3',
        data: [21]
      },
      {
        name: 'Renters',
        year: '2022',
        quarter: 'Q4',
        data: [18]
      },
      {
        name: 'Home',
        year: '2022',
        quarter: 'Q1',
        data: [12]
      },
      {
        name: 'Home',
        year: '2022',
        quarter: 'Q2',
        data: [9]
      },
      {
        name: 'Home',
        year: '2022',
        quarter: 'Q3',
        data: [28]
      },
      {
        name: 'Home',
        year: '2022',
        quarter: 'Q4',
        data: [28]
      },
      {
        name: 'Auto',
        year: '2022',
        quarter: 'Q1',
        data: [21]
      },
      {
        name: 'Auto',
        year: '2022',
        quarter: 'Q2',
        data: [19]
      },
      {
        name: 'Auto',
        year: '2022',
        quarter: 'Q3',
        data: [20]
      },
      {
        name: 'Auto',
        year: '2022',
        quarter: 'Q4',
        data: [23]
      },
      {
        name: 'Term Life',
        year: '2022',
        quarter: 'Q1',
        data: [21]
      },
      {
        name: 'Term Life',
        year: '2022',
        quarter: 'Q2',
        data: [8]
      },
      {
        name: 'Term Life',
        year: '2022',
        quarter: 'Q3',
        data: [25]
      },
      {
        name: 'Term Life',
        year: '2022',
        quarter: 'Q4',
        data: [20]
      }],
    totalSales: [{
      year: '2021',
      data: [16, 13, 22, 21, 25, 24, 12, 28, 11, 29, 27, 17]
    }, {
      year: '2022',
      data: [10, 24, 17, 19, 20, 26, 29, 22, 23, 11, 12, 13]
    },
      {
        year: '2023',
        data: [16, 23, 12, 19, 13, 30, 27, 26, 14, 17, 29, 11]
      },
      {
        year: '2024',
        data: [12, 27, 20, 13, 30, 15, 19, 16, 25, 14, 24, 21]
      }],
    pageLoaded: false
  },
  getters,
  actions,
  mutations,
  modules: {
    canadianTermLife,
    borderfree,
    afficiency,
    tugoData,
    stillwaterHomeData,
    stillwaterCaliforniaQuestions,
    stillwaterRenters,
    stillwaterHome,
    stillwaterCondo,
    comms,
    accounts,
    documents,
    verifyEmail,
    customerQuote,
    addressValidation
  },
  plugins: [createPersistedState({
    paths: ['user', 'activeUser']
  })]
})
