<template>
  <div
    class="hero-wrapper"
    :style="{ backgroundImage: `url(${backgroundUrl})`, paddingInline: paddingInline, height: height, backgroundPosition: backgroundPosition, backgroundSize: backgroundSize }"
  >
    <div
      class="hero-container"
      :style="{ alignSelf: align, gap: gap}"
    >
      <div
        v-if="btnLocation === 'above'"
        class="mb-12"
      >
        <v-btn
          v-if="hasClickProp || btnLink"
          :class="isCentered ? 'font-weight-bold text-h4 align-self-center' : 'font-weight-bold text-h4 align-self-start' "
          :style="{ color: btnTextColor }"
          x-large
          :color="btnColor"
          @click="handleClick"
        >
          {{ btnText }}
        </v-btn>
      </div>
      <div
        class="sora white--text"
        :style="{fontSize: headingFontSize}"
      >
        <slot />
      </div>
      <div v-if="btnLocation === 'below'">
        <v-btn
          v-if="hasClickProp || btnLink"
          :class="isCentered ? 'font-weight-bold text-h4 align-self-center' : 'font-weight-bold text-h4 align-self-start' "
          :style="{ color: btnTextColor }"
          large
          :color="btnColor"
          @click="handleClick"
        >
        <v-icon
          v-if="firstBtnTextICon"
          x-medium
          style="color: black;"
        >
         {{firstBtnTextICon}}
        </v-icon>
        {{ btnText }}
        </v-btn>
          <v-btn
          v-if="secondBtnText"
          class='font-weight-bold text-h4 align-self-start'
          :style="{ color: btnTextColor }"
          large
          :color="btnColor"
          @click="handleClickSecondary"
        >
        <v-icon
          v-if="secondBtnTextICon"
          x-medium
          style="color: black;"
        >
         {{secondBtnTextICon}}
        </v-icon>
          {{ secondBtnText }}
        </v-btn>
        <div
        v-if="heroText && heroImages && heroImages.length"
        class="hero-secondary-container"
        >
        <div
         v-if="heroText"
         class="sora white--text secondary-hero-text"
        >
        {{heroText}}
        </div>
       <div
      v-if="heroImages && heroImages.length"
      class="image-gallery"
      >
        <div
        v-for="(image, index) in heroImages"
        :key="index"
        class="image-item"
        >
        <img
        height="80px"
        :src="image"
        :alt="'Image ' + (index + 1)"
        />
        </div>
       </div>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { gtag_report_conversion } from '../../utils/common'

  export default {
    props: {
      size: { type: String, default: 'large' },
      align: { type: String, default: 'flex-end' },
      btnLink: { type: String, default: undefined },
      btnText: { type: String, default: '' },
      btnColor: { type: String, default: '#AAF0C1' },
      btnLocation: { type: String, default: 'below' },
      btnTextColor: { type: String, default: '#292F36' },
      backgroundUrl: { type: String, default: '' },
      backgroundPosition: { type: String, default: 'center' },
      backgroundSize: { type: String, default: 'cover' },
      gap: { type: String, default: '40px' },
      headingFontSize: { type: String, default: '52px' },
      isCentered: { type: Boolean, default: false, required: false },
      secondBtnText: { type: String, default: '' },
      secondBtnTextLink: { type: String, default: '/advisor' },
      secondBtnTextICon: { type: String, default: '' },
      heroText: { type: String, default: '' },
      heroImages: { type: Array, default: () => [] },
      firstBtnTextICon: { type: String, default: '' }
    },
    computed: {
      isXl () {
        return this.$vuetify.breakpoint.xlOnly
      },
      isTablet () {
        return this.$vuetify.breakpoint.smAndDown
      },
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      },
      paddingInline () {
        return this.isTablet ? '5%' : '7%'
      },
      height () {
        return this.isXl ? '1035px' : '600px'
      },
      hasClickProp () {
        return Object.prototype.hasOwnProperty.call(this.$listeners, 'click')
      }
    },
    methods: {
      handleClick () {
        if (this.btnLink === '/quote') { gtag_report_conversion(this.$gtag, process.env.VUE_APP_FORM_START) }
        this.$router.push(this.btnLink)
        if (Object.prototype.hasOwnProperty.call(this.$listeners, 'click')) {
          this.$emit('click')
        }
      },
      handleClickSecondary () {
        window.location.href = this.secondBtnTextLink
      }
    }
  }
</script>

<style scoped>
.hero-wrapper {
  position: relative;
  display: flex;
  padding-block: 50px;
  padding-inline: 9%;
  width: 100vw;
  /* background-size: cover; */
  background-repeat: no-repeat;
}

.hero-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.sora {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  /* font-size: 52px; */
  line-height: 110%;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
  color: #FFFFFF;
}

.mdi {
    position: relative;
    left: -10px;
    top: -2px;
}
.secondary-hero-text {
  margin-top:100px;
  font-size: 42px;
}
.image-gallery{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.image-gallery .image-item:nth-child(2) img  {
  height: 80px;
}
.image-gallery .image-item:nth-child(1) img  {
  height: 40px;
}
.hero-secondary-container {
  position: absolute;
  bottom: 20px;
}
</style>
